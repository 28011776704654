import {
	CheckCircleFilled,
	CloseCircleFilled,
	LoadingOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Input, Row, Space, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useState } from 'react';
import { CustomNotification } from 'src/components/custom-notification';
import { tableColumnTextFilterConfig } from 'src/components/tableUtils';
import {
	CmsDisableUser,
	CmsDisableUserVariables,
} from 'src/lib/gql/generated/CmsDisableUser';
import {
	CmsGetPaginatedUsers,
	CmsGetPaginatedUsersVariables,
} from 'src/lib/gql/generated/CmsGetPaginatedUsers';
import {
	CmsUpdateUserType,
	CmsUpdateUserTypeVariables,
} from 'src/lib/gql/generated/CmsUpdateUserType';
import {
	MutationDisableUser,
	MutationUpdateUserType,
} from 'src/lib/gql/mutations';
import { QueryGetUsers } from 'src/lib/gql/queries';
import { OffersTable, UsersTable } from 'src/lib/interface';
import InfoTable from '../components/info-table';
import { Role } from '../lib/gql/generated/globalTypes';

const Photographers = () => {
	const [pageNumber, setPageNumber] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [userEmail, setUserEmail] = useState<string>('');

	const {
		data: qryData,
		loading,
		refetch: refetchQuery,
	} = useQuery<CmsGetPaginatedUsers, CmsGetPaginatedUsersVariables>(
		QueryGetUsers,
		{
			fetchPolicy: 'cache-and-network',
			variables: {
				type: Role.PHOTOGRAPHER,
				pageNumber: pageNumber,
				perPage: perPage,
				showAll: true,
				email: userEmail,
			},
		},
	);

	const p_Photographers: UsersTable[] = [];

	qryData?.pagination?.users?.result?.forEach((user, i) => {
		return p_Photographers.push({
			id: user?.id || '',
			about: user?.about || '',
			key: (i + 1).toString(),
			name: `${user?.fName || ''} ${user?.lName || ''}`,
			createDateTime: user?.createDateTime || '',
			dob: user?.dob || '',
			email: user?.email || '',
			enabled: user?.enabled || false,
			gender: user?.gender || '',
		});
	});

	const [disableUser] = useMutation<CmsDisableUser, CmsDisableUserVariables>(
		MutationDisableUser,
	);

	const [updateUserType] = useMutation<
		CmsUpdateUserType,
		CmsUpdateUserTypeVariables
	>(MutationUpdateUserType);

	// Fields
	const columns = [
		{
			title: 'ID',
			dataIndex: 'key',
			width: 50,
			align: 'center',
			render: (key: number) => {
				return key;
			},
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			width: 200,
			...tableColumnTextFilterConfig<OffersTable>(),
			onFilter: (value, record) => {
				return record.name
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Gender',
			dataIndex: 'gender',
			key: 'gender',
			width: 80,
			align: 'center',
			filters: [
				{
					text: 'Male',
					value: 'male',
				},
				{
					text: 'Female',
					value: 'female',
				},
			],
			filterMultiple: false,
			onFilter: (value, record) => {
				return record.gender.toLowerCase() === value.toLowerCase();
			},
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			width: 200,
			...tableColumnTextFilterConfig<UsersTable>(),
			onFilter: (value, record) => {
				return record.email
					.toString()
					.toLowerCase()
					.includes(value.toString().toLowerCase());
			},
		},
		{
			title: 'Date of Birth',
			dataIndex: 'dob',
			key: 'dob',
			align: 'center',
			width: 200,
			sorter: (a, b) => {
				return new Date(a.dob).valueOf() - new Date(b.dob).valueOf();
			},
			render: (dob: string) => dob,
		},
		{
			title: 'Edit',
			key: 'enabled',
			width: 80,
			align: 'center',
			filters: [
				{
					text: 'Enabled',
					value: true,
				},
				{
					text: 'Disabled',
					value: false,
				},
			],
			filterMultiple: false,
			onFilter: (value, record) => {
				return record.enabled === value;
			},
			render: (props: { enabled: boolean; id: string }) => {
				const { enabled, id } = props;
				return (
					<Space>
						<CheckCircleFilled
							onClick={async () => {
								const rsp = await disableUser({
									variables: {
										userId: id,
										enabled: !enabled,
									},
								});

								if (rsp.data) {
									CustomNotification({
										pageName: 'Users',
										pagePrefix: 'User',
										notificationType: 'updated',
									});

									refetchQuery();
								}
							}}
							style={{
								color: enabled ? 'var(--valid)' : 'var(--inactive)',
								width: '12px',
								height: '12px',
							}}
						/>
						<CloseCircleFilled
							onClick={async () => {
								if (
									!confirm(
										'You are removing this photographer. Do  you confirm?',
									)
								)
									return;

								const rsp = await updateUserType({
									variables: {
										type: Role.USER,
										userId: id,
									},
								});
								if (rsp.data) {
									CustomNotification({
										pageName: 'Users',
										pagePrefix: 'User',
										notificationType: 'updated',
									});
									refetchQuery();
								}
							}}
							style={{
								color: 'var(--invalid)',
								width: '16px',
								height: '16px',
							}}
						/>
					</Space>
				);
			},
		},
	];

	return (
		<Row>
			<Col
				span={24}
				style={{
					marginBottom: '2rem',
				}}
			>
				<Text
					className="sub-header"
					style={{
						fontSize: 'clamp(16px, 1vw, 1.1vw)',
					}}
				>
					Photographers
				</Text>
			</Col>

			<Col span={24}>
				<Spin indicator={<LoadingOutlined />} spinning={loading ? true : false}>
					<InfoTable
						data={p_Photographers}
						columnData={columns}
						onChange={(pagination, filters, sorter) => {
							setPerPage(pagination?.pageSize);
							setPageNumber(pagination?.current);

							setUserEmail(filters?.email?.toString().toLowerCase() || '');
						}}
						expandable={{
							expandedRowRender: (record) => (
								<label>
									Admin Comment
									<Input
										suffix={
											<Button
												ghost
												type="primary"
												size="small"
												style={{
													fontSize: 'clamp(9px, 1vw, 10px)',
													color: 'var(--valid)',
													borderColor: 'var(--valid)',
												}}
												onClick={(e) => {
													e.stopPropagation();
												}}
											>
												UPDATE
											</Button>
										}
									/>
								</label>
							),
						}}
						pagination={{
							total:
								qryData?.pagination?.users?.paginationInfo?.totalResult || 0,
						}}
						other={{
							scroll: {
								x: 300,
							},
						}}
					/>
				</Spin>
			</Col>
		</Row>
	);
};

export default Photographers;
